import thangDiemImage from './assets/thangdiemnguycodagen.png';
export const newsData = [
  {
    id: 1,
    title: "Thang Điểm Nguy Cơ ĐA GEN: Khuynh Hướng Mới Trong Ước Tính Nguy Cơ Bệnh Động Mạch Vành ",
    description: "Theo các hướng dẫn, phân tầng nguy cơ và kiểm soát các yếu tố nguy cơ là chiến lược phòng ngừa để giảm tử vong và tàn phế bệnh nhân. Bên cạnh các yếu tố nguy cơ truyền thống, các nghiên cứu về tương quan hệ gen đã góp phần làm sáng tỏ một số các biến thể di truyền có liên quan đến bệnh động mạch vành.",
    content1: 'Kính thưa các quý Bác sĩ/Dược sĩ, \n Theo các hướng dẫn, phân tầng nguy cơ và kiểm soát các yếu tố nguy cơ là chiến lược phòng ngừa để giảm tử vong và tàn phế bệnh nhân. Bên cạnh các yếu tố nguy cơ truyền thống, các nghiên cứu về tương quan hệ gen đã góp phần làm sáng tỏ một số các biến thể di truyền có liên quan đến bệnh động mạch vành.',
    content2: `Các nỗ lực phát triển trong thập kỷ qua đã cho ra đời thang điểm nguy cơ đa gen, thang điểm này là định lượng nguy cơ bằng cách cộng hợp tác động của số lượng lớn biến thể di truyền thành con số duy nhất để phân tầng nguy cơ di bệnh động mạch vành. Với độ chính xác cao, thang điểm này đã được sử dụng rộng rãi trên thế giới trong những năm gần đây, đặc biệt, Hiệp hội Tim Hoa kỳ (AHA 2022) đã đồng thuận về vai trò và ứng dụng thang điểm này trong thực hành lâm sàng.\n
      Chương trình này sẽ cập nhật một số kinh nghiệm trong nghiên cứu và ứng dụng thang điểm nguy cơ đa gen cho bệnh động mạch vành tại Khoa Nội Tim Mạch, Bệnh viện Thống Nhất, đồng thời, cập nhật một số các nghiên cứu mới về thang điểm nguy cơ đa gen cho bệnh động mạch vành nhóm bệnh có nguy cơ cao như bệnh tăng cholesterol máu gia đình và bệnh nhân đái tháo đường típ 2.
      Chủ tọa:
      · PGS.TS.BS. Lê Đình Thanh – Giám Đốc Bệnh viện Thống Nhất
      · PGS.TS.BS Hồ Thượng Dũng – Phó Giám Đốc Bệnh viện Thống Nhất
      Báo cáo viên:
      · TS.BS. Nguyễn Thanh Huân – Phó khoa Nội Tim Mạch, Bệnh Viện Thống Nhất
      · ThS BS Trần Công Duy – Khoa Nội Tim, Mạch Bệnh viện Chợ Rẫy
      · BS.CKI Lê Hoàng Bảo – Khoa Nội Tiết, Bệnh viện Đại Học Y Dược TP.HCM
      Thời gian: 13:15 – 15:25 Thứ Năm ngày 28/03/2024
      Cấp CME miễn phí cho bác sĩ đạt yêu cầu.
      Trân trọng kính mời các Bác sĩ có quan tâm tham gia và đóng góp ý kiến.`,
    imageUrl: thangDiemImage,
    date: "October 4, 2024",
  },
];
