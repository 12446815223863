import artical1img1 from './assets/artical1img1.png';
import artical1img2 from './assets/artical1img2.png';
export const newsData = [
  {
    id: 1,
    title: "Webinar số 01 - Thang Điểm Nguy Cơ ĐA GEN: Một góc nhìn mới trong phòng ngừa bệnh tim mạch xơ vữa",
    content1: `
    Kính thưa các quý Bác sĩ/Dược sĩ,

    Mặc cho các tiến bộ trong chẩn đoán, điều trị, từ năm 1990 đến 2019, bệnh động mạch vành vẫn là một trong những nguyên nhân tử vong, cũng như giảm số năm sống có chất lượng (DALYs) hàng đầu trên thế giới và Việt Nam. Theo các hướng dẫn, phân tầng bằng các công cụ ước tính nguy cơ tim mạch và kiểm soát các yếu tố nguy cơ là chiến lược phòng ngừa phù hợp để giảm tử vong và tàn phế bệnh nhân. Bên cạnh các yếu tố nguy cơ truyền thống, các nghiên cứu về tương quan hệ gen đã góp phần làm sáng tỏ một số các biến thể di truyền có liên quan đến bệnh động mạch vành.

    Trong thập kỷ qua, các nổ lực phát triển và chuẩn hóa đã cho ra đời thang điểm nguy cơ đa gen, thang điểm này là thước đo giúp định lượng nguy cơ di truyền bằng cách tích hợp thông tin của số lượng lớn, lên đến hàng triệu các biến thể thành một con số duy nhất để phân tầng nguy cơ di truyền của một người. Với khả năng phân tầng, tiên lượng sớm bệnh động mạch vành, thang điểm này đã được sử dụng rộng rãi trên thế giới trong những năm gần đây, đặc biệt, Hiệp hội tim mạch Hoa kỳ (AHA 2022) đã đồng thuận về vai trò và ứng dụng thang điểm này trong thực hành lâm sàng. Chương trình hội thảo trực tuyến này sẽ giúp cập nhật về cơ chế phát triển và ứng dụng của thang điểm nguy cơ đa gen cho bệnh động mạch vành trong lâm sàng, cũng như chia sẽ chiến lược phòng ngừa bệnh tim mạch xơ vữa.

    Chủ tọa: GS.TS.BS. Trương Quang Bình – Chủ tịch Phân hội Xơ Vữa Động Mạch Việt Nam

    Báo cáo viên:

    · TS.BS. Nguyễn Thanh Huân – Phó khoa Nội Tim Mạch, Bệnh Viện Thống Nhất - BM Lão Khoa, ĐH Y Dược TP.HCM

    · TS.BS. Trần Hòa - Phó khoa Can Thiệp Tim Mạch, BV ĐH Y Dược TP.HCM - Tổng thư ký, Phân hội Xơ Vữa Động Mạch Việt Nam

    Tham gia thảo luận:

    · ThS.Bs Trần Công Duy - Khoa Nội Tim Mạch, Bệnh viện Chợ Rẫy - BM Nội tổng quát, ĐH Y Dược TP.HCM

    Thời gian tổ chức: Thứ Bảy ngày 13/01/2024 từ 13:30 đến 14:45.

    Hội Tim mạch xin mời các Bác sĩ có quan tâm tham gia và đóng góp ý kiến về Hội thảo.

    Trân trọng!
    `,
    youtubeContents: [
      {
        title: 'THANG ĐIỂM NGUY CƠ ĐA GEN: Góc Nhìn Mới Trong Dự Trong Dự Phòng Bệnh Tim Mạch Xơ Vữa',
        url: 'https://www.youtube.com/embed/i9FWKnuUZEY?si=CXry1xLMWA0gBFQ4'
      },
      {
        title: 'Góc Nhìn Mới Về Ứng Dụng THANG ĐIỂM NGUY CƠ ĐA GEN trong Phòng Ngừa Bệnh TMXV - TS.BS Trần Hoà',
        url: 'https://www.youtube.com/embed/ApNbmS7p0I8?si=Ve5vzdXLEfRGBEPw'
      },
      {
        title: 'Thang Điểm NGUY CƠ ĐA GEN: Khuynh Hướng Mới Trong Ước Tính Nguy Cơ Bệnh Mạch Vành | HPS',
        url: 'https://www.youtube.com/embed/a5FDw0PO5rU?si=YVfydaVwWl_Hb6Fm'
      },
    ],
    date: "October 4, 2024",
  },
  {
    id: 2,
    title: "Artical số 01: Sự khác biệt về di truyền đơn gen và đa gen trong bệnh lý tim mạch",
    // youtubeUrl: 'https://www.youtube.com/embed/a5FDw0PO5rU?si=vDub0iCDWxrU0IyO',
    imgs: [artical1img1, artical1img2],
    date: "October 4, 2024",
  },
];
