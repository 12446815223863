import React, {useState, useEffect} from 'react';
import { Layout, Menu, Row, Col, Divider, Grid, Input, Card } from 'antd';
import './LandingPage.css'; // Optional for custom styling
import logo from './assets/logo.png';
import homeoffice from './assets/homeoffice.png';
import whatwedo from './assets/whatwedo.png';
import News from './News';
import OurPartners from './OurPartners';
import ConnectWithUs from './ConnectWithUs';
import focus from './assets/focus.png';
import NavigationLandingPage from './Layout/NavigationLandingPage';

const { useBreakpoint } = Grid;

const { Header, Content } = Layout;

const LandingPage = () => {
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [menuState, setMenuState] = useState('content');
  const screens = useBreakpoint(); // Get the current screen size

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      setBannerHeight(`calc(100vh - ${0}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Layout>
      <NavigationLandingPage />
    
      <div id='content-page' style={{marginTop:'50px'}}>
        <div  className="banner" style={{ height: screens.md? bannerHeight: '500px' }}>
          <div className="banner-overlay" >
          </div>
          <div justify="center" align="middle" className="banner-content" >
              <h1 style={{textAlign:'center'}} className="banner-title">HEALTH PRECISION</h1>
              <p  style={{textAlign:'center'}} className="banner-description">
                THE NEW ERA OF PRECISION MEDICINE
              </p>
            </div>
        </div>

        {
          screens.md ? <>
            <Content className="general-info" style={{padding:'50px 150px'}}>
              <Row>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-text' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)'}}>
                    <h2>ABOUT US</h2>
                    <p >
                    Established in 2022, Health Precision Service Limited is a pioneer in bringing genetic-based platforms to the healthcare industries. We are passionate about recognising the importance of genetic innovation for human health. We believe that each person is unique, and we would like to bring the best out of them. Our culture is the lighthouse that guides the Health Precision Service into a bright future.
                    </p>
                  </div>
                </Col>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-img' style={{backgroundColor:'#fff', color:'#fff'}}>
                    <img src={homeoffice} alt='process'/>
                  </div>
                </Col>
              </Row>
              <Row>
              <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-img' style={{backgroundColor:'#fff', color:'#fff'}}>
                    <img src={whatwedo} alt='homeoffice'/>
                  </div>
                </Col>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-text' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)'}}>
                    <h2>What we do</h2>
                    <ul style={{fontSize:'16px', lineHeight:'1.5', padding: '10px'}}>
                      <li>Corporate to commercialize Genetic-based Healthcare Flatform.</li>
                      <li>Research-sponsor to leverage patient’s access to genetic-based products and technologies</li>
                      <li>Trusted partner with local healthcare initiatives to improve healthcare landscape</li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-text' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)'}}>
                    <h2>Our Focus</h2>
                    <p style={{margin:0}}>
                      Our focus are high technology and affordable products for the local:
                    </p>
                    <ul style={{fontSize:'16px', lineHeight:'1.5', padding: '10px'}}>
                      <li>Genetic-based flatform for precise Healthcare.</li>
                      <li>Polygenic risk score for chronic disease.</li>
                      <li>Drug Metabolism and interaction.</li>
                    </ul>
                  </div>
                </Col>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-img' style={{backgroundColor:'#fff', color:'#fff'}}>
                    <img src={focus} alt='vision'/>
                  </div>
                </Col>
              </Row>
            </Content>  
          </> : <>
            <Content className="general-info">
              <Row>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-text' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)'}}>
                    <h2>ABOUT US</h2>
                    <p >
                    Established in 2022, Health Precision Service Limited is a pioneer in bringing genetic-based platforms to the healthcare industries. We are passionate about recognising the importance of genetic innovation for human health. We believe that each person is unique, and we would like to bring the best out of them. Our culture is the lighthouse that guides the Health Precision Service into a bright future.
                    </p>
                  </div>
                </Col>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-img' style={{backgroundColor:'#fff', color:'#fff'}}>
                    <img src={homeoffice} alt='process'/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-text' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)'}}>
                    <h2>What we do</h2>
                    <ul style={{fontSize:'16px', lineHeight:'1.5', padding: '10px'}}>
                      <li>Corporate to commercialize Genetic-based Healthcare Flatform.</li>
                      <li>Research-sponsor to leverage patient’s access to genetic-based products and technologies</li>
                      <li>Trusted partner with local healthcare initiatives to improve healthcare landscape</li>
                    </ul>
                  </div>
                </Col>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-img' style={{backgroundColor:'#fff', color:'#fff'}}>
                    <img src={whatwedo} alt='homeoffice'/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-text' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)'}}>
                    <h2>Our Focus</h2>
                    <p style={{margin:0}}>
                      Our focus are high technology and affordable products for the local:
                    </p>
                    <ul style={{fontSize:'16px', lineHeight:'1.5', padding: '10px'}}>
                      <li>Genetic-based flatform for precise Healthcare.</li>
                      <li>Polygenic risk score for chronic disease.</li>
                      <li>Drug Metabolism and interaction.</li>
                    </ul>
                  </div>
                </Col>
                <Col sx={24} sm={24} md={12} lg={12} xl={12}>
                  <div className='general-info-img' style={{backgroundColor:'#fff', color:'#fff'}}>
                    <img src={focus} alt='vision'/>
                  </div>
                </Col>
              </Row>
            </Content>  
          </>
        }
        

        <Content className="news" style={{backgroundColor:'#f2f2f2'}}>
          <News />
        </Content>

        {/* <Content className="our-products" style={{backgroundColor:'#fff', padding:'0px 100px'}}>
        </Content> */}

        <Content className="our-partners" style={{backgroundColor:'#fff', padding: '30px 0'}}>
          <OurPartners />
        </Content>

        <Content className="our-partners" style={{backgroundColor:'#001628'}}>
          <ConnectWithUs />
        </Content>

        <Content className="footer" style={{backgroundColor:'#fff', padding:'20px'}}>
          <Row className="footer-content">
            <Col  sx={24} sm={24} md={8} lg={8} xl={8} className='footer-item' style={{display:'flex', alignItems:'center', paddingBottom:'10px'}}>
              <img src={logo} alt='logo' style={{width:'50%'}}/>
            </Col>
            <Col sx={24} sm={24} md={16} lg={16} xl={16} className='footer-item' style={{display:'flex'}}>
              <h2 style={{color:'#001628'}}>HEALTH PRECISION SERIVCE</h2>
              <p style={{color:'#001628'}}>Address: Room 2 – Floor 8 – Pearl Plaza, 561A Dien Bien Phu Street, Ward 25, Binh Thanh District, Ho Chi Minh City, Vietnam</p>
              <p style={{color:'#001628'}}>Phone: +84 (0) 93 407 8418</p>
              <p style={{color:'#001628'}}>Email: admin@healthprecisionservice.com</p>
            </Col>
          </Row>
        </Content>
        <Divider style={{backgroundColor:'#a3a3a3', width:'100vw !important', margin:0}}/>
        <Content className="footer" style={{backgroundColor:'#fff'}}>
          <Row className="footer-content">
            <Col span={24} className='footer-item' style={{alignItems:'center', marginTop:'20px'}}>
              <p style={{color:'#001628'}}>©2024 by Health Precision Service LTD All Rights Reserved</p>
            </Col>
          </Row>
        </Content>
      </div>
    </Layout>
  );
};

export default LandingPage;