import React, {useState, useEffect} from "react";
import { Card, Row, Col, Breadcrumb, Typography, Image , Grid} from "antd";
import NavigationLandingPage from "../Layout/NavigationLandingPage";
import FooterLandingPage from "../Layout/FooterLandingPage";
import { newsData }  from "./data";
import { useNavigate } from 'react-router-dom';
import Link from "antd/es/typography/Link";
import './new.css'

const { Title, Paragraph } = Typography;

const truncateText = (text, maxWords) => {
  const wordsArray = text.split(' '); // Split text into an array of words
  if (wordsArray.length > maxWords) {
    return wordsArray.slice(0, maxWords).join(' ') + '...'; // Join first 'maxWords' words and add '...'
  }
  return text; // If text is already within the limit, return it unchanged
};

const ListNewPage = () => {
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [headerHeight, setHeaderHeight] = useState(0);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      const footerHeight = document.querySelector('.footer-component').offsetHeight;
      setHeaderHeight(headerHeight);
      setBannerHeight(`calc(100vh - ${headerHeight+footerHeight}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const screens = useBreakpoint(); // Get the current screen size

  // Find the specific news item based on the ID
  return (
    <div>
      <NavigationLandingPage />
      <div style={{paddingTop:`${headerHeight}px`, minHeight:bannerHeight, padding: `${headerHeight}px ${screens.md?100:30}px`}}>
        <Breadcrumb style={{margin:'10px 0'}}>
            <Breadcrumb.Item>
              <a href="/">Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>News</Breadcrumb.Item>
        </Breadcrumb>
        <Title level={2}>News</Title>
        <Row gutter={[16, 16]}>
          {newsData.map((news, index) => (
            <Col xs={24} sm={12} lg={8} key={index}>
              <Link href={`/news/${news.id}`} className="list-news-image-container">
                <Image
                  className="list-news-image"
                  src={news.imageUrl}
                  alt={news.title}
                  preview={false}
                />
              </Link>

              <Link 
                href={`/news/${news.id}`}
                style={{ textDecoration: 'none' }} // Initially no underline
                onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'} // Add underline on hover
                onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'} // Remove underline on hover out
              >
                <Title style={{margin:'20px 0'}} level={5}>{truncateText(news.title, 30)}</Title>
              </Link>

              <Paragraph>{truncateText(news.description, 50)}</Paragraph>
              <Paragraph>{news.date}</Paragraph>
            </Col>
          ))}
        </Row>
      </div>
      <FooterLandingPage />
    </div>

  );
};

export default ListNewPage;