import React from 'react';
import { Card, Col, Row } from 'antd';
import { newsData } from './New/data';


const News = () => {
  return (
    <div style={{ padding: '20px', textAlign:'center' }}>
      <h2> News </h2>
      <Row gutter={[16, 16]}> {/* Adds spacing between columns */}
        {newsData.map((item, index) => (
          <Col xs={12} sm={12} md={6} lg={6} xl={6} key={index}> {/* 2 items for mobile, 3 for web */}
            <Card
              hoverable
              onClick={() => window.location.href = `/news/${item.id}`}
              title={item.title}
              cover={<img alt="news" src={item.imageUrl} />} // Optional: Add image if needed
            >
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default News;
